import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllGalleries } from "../../services/gallerieServices"; // Assurez-vous que le chemin est correct
import Button from "../../components/Button/Button";
import { useUser } from "../Home/UserContext";

const LastGallerie = () => {
  const [galleries, setGalleries] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const allGalleries = await getAllGalleries(); // Fonction API pour obtenir les galeries
        const activeGalleries = allGalleries.filter(gallery => gallery.is_active); // Filtrer les galeries actives
        setGalleries(activeGalleries.slice(0, 3)); // Gardez seulement les trois premières galeries actives
      } catch (error) {
        console.error("Failed to fetch galleries", error);
      }
    };

    fetchGalleries();
  }, []);

  const handleReadMore = (galleryId) => {
    navigate(`/galerie-photos/album/${galleryId}`);
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Dernièrs Albums Photos
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        {galleries.map((gallery) => (
          <div
            key={gallery.id}
            className="flex flex-col bg-slate-100 dark:bg-slate-500 shadow-md rounded-lg p-4 justify-between h-full"
          >
            <div>
              {/* Vérifier si gallery.photos existe et qu'il a au moins une photo, sinon montrer une image par défaut */}
              <img
                src={
                  gallery.photos && gallery.photos.length > 0
                    ? gallery.photos[0].photo_url
                    : "/img/464337.jpg"
                }
                alt={gallery.titre}
                className="w-full h-64 object-cover rounded-t-lg"
              />
              <h3 className="text-xl font-bold mb-2">{gallery.titre}</h3>
              <p className="text-sm text-gray-600 dark:text-slate-300 mb-4">
                {gallery.description}
              </p>
            </div>
            {user && (
              <Button
                text="Voir Album"
                onClick={() => handleReadMore(gallery.id)}
                className="self-center mt-auto"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastGallerie;
