// Catégorie de poids
function getWeightCategory(gender, ageCategory, weight) {
  const weightCategories = {
    MiniPoussin: [20, 25, 30, 35, 40],
    Poussin: [20, 25, 30, 35, 40, 45],
    Pupille: [25, 30, 35, 40, 45, 50],
    Benjamin: [30, 35, 40, 45, 50, 55],
    Minime: [35, 40, 45, 50, 55, 60, 65],
    Cadet: [52, 57, 63, 70],
    Junior: [55, 61, 68, 76],
    Sénior: [60, 67, 75, 84],

    MiniPoussinF: [20, 25, 30, 35],
    PoussinF: [20, 25, 30, 35, 40],
    PupilleF: [20, 25, 30, 35, 40, 45],
    BenjaminF: [30, 35, 40, 45, 50],
    MinimeF: [35, 40, 45, 50, 55],
    CadetF: [47, 54, 61],
    JuniorF: [48, 53, 59, 66],
    SéniorF: [50, 55, 61, 68],
  };

  // Gérer les poids non renseignés ou égaux à 0
  if (!weight || weight === 0) {
    return "Non pesé(e)";
  }

  // Assurer que le poids est un nombre
  let numericWeight = parseFloat(weight);
  if (isNaN(numericWeight)) {
    return "Poids invalide";
  }

  // Modifier la clé en fonction du genre
  let categoryKey;
  if (gender === "Masculin") {
    categoryKey = ageCategory; // Correspond aux clés masculines
  } else {
    categoryKey = `${ageCategory}F`; // Correspond aux clés féminines
  }

  // Gérer les catégories d'âge inconnues
  if (ageCategory === "Inconnu") {
    return "Catégorie d'âge inconnue";
  }

  // Vérifier si la clé existe
  if (!weightCategories.hasOwnProperty(categoryKey)) {
    console.error(`Catégorie inconnue: ${categoryKey}`);
    return "Catégorie inconnue";
  }

  // Récupérer la dernière catégorie (la plus lourde) pour la comparaison
  let lastCategory =
    weightCategories[categoryKey][weightCategories[categoryKey].length - 1];

  // Si le poids est supérieur à la dernière catégorie, retourner cette catégorie
  if (numericWeight >= lastCategory) {
    return `+${lastCategory} kg`;
  }

  // Trouver la catégorie de poids appropriée
  let selectedCategory = weightCategories[categoryKey].find(
    (cat) => numericWeight < cat
  );
  return selectedCategory === null
    ? "Aucune catégorie"
    : `-${selectedCategory} kg`;
}

export default getWeightCategory;
