import React, { useState } from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "../../Home/ScrollIndicator";
import { FaChevronUp, FaChevronDown, FaTimes } from "react-icons/fa";

const Dropdown = ({ title, children, isOpen, toggle }) => (
  <div className="bg-blue-100 rounded-lg shadow mb-4 p-4 max-w-[800px] mx-auto w-full">
    <button
      onClick={toggle}
      className="w-full flex justify-between items-center text-left text-lg font-semibold dark:text-gray-800"
    >
      {title}
      {isOpen ? <FaChevronUp /> : <FaChevronDown />}
    </button>
    {isOpen && <div className="mt-2">{children}</div>}
  </div>
);

const ArbitrageKarate = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);

  const toggleDropdown1 = () => setIsOpen1(!isOpen1);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleDropdown3 = () => setIsOpen3(!isOpen3);
  const toggleDropdown4 = () => setIsOpen4(!isOpen4);
  const toggleDropdown5 = () => setIsOpen5(!isOpen5);
  const toggleDropdown6 = () => setIsOpen6(!isOpen6);
  const toggleDropdown7 = () => setIsOpen7(!isOpen7);
  const toggleDropdown8 = () => setIsOpen8(!isOpen8);
  const toggleDropdown9 = () => setIsOpen9(!isOpen9);
  const toggleDropdown10 = () => setIsOpen10(!isOpen10);
  const toggleDropdown11 = () => setIsOpen11(!isOpen11);
  const toggleDropdown12 = () => setIsOpen12(!isOpen12);
  const toggleDropdown13 = () => setIsOpen13(!isOpen13);
  const toggleDropdown14 = () => setIsOpen14(!isOpen14);
  const toggleDropdown15 = () => setIsOpen15(!isOpen15);
  const toggleDropdown16 = () => setIsOpen16(!isOpen16);

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Arbitrage Karaté
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mt-4 space-y-8 mx-auto p-4 max-w-[1280px]">

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Rôle de l'arbitre</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <p>🔸Assurer la sécurité des combattants</p>
              <p>🔸Faire respecter les règles du karaté</p>
              <p>🔸Attribuer les points et les pénalités</p>
              <p>🔸Déterminer le vainqueur du combat</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916971/fake/Portrait-Arbitre-H.-Fort-Morot-1925x750_pnvk4u.jpg" alt="Rôle de l'arbitre" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Les principales commandes de l'arbitre</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916649/fake/A14I1833-500x250_lmbria.jpg" alt="Gestes de l'arbitre" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Shobu Hajime : Début du combat" isOpen={isOpen1} toggle={toggleDropdown1}>
                <p>Shobu Hajime est le terme japonais pour dire commencer le combat.</p>
              </Dropdown>
              <Dropdown title="Yame : Arrêt temporaire du combat" isOpen={isOpen2} toggle={toggleDropdown2}>
                <p>Yame : l'arbitre interrompt le combat.</p>
              </Dropdown>
              <Dropdown title="Tsuzukete Hajime : Reprise du combat" isOpen={isOpen3} toggle={toggleDropdown3}>
                <p>Tsuzukete Hajime : reprise du combat après un arrêt temporaire.</p>
              </Dropdown>
              <Dropdown title="Shobu Ippon Hajime : Début du combat avec un seul point nécessaire" isOpen={isOpen4} toggle={toggleDropdown4}>
                <p>Shobu Ippon Hajime signifie commencer un combat où un seul point est nécessaire pour gagner.</p>
              </Dropdown>
              <Dropdown title="Hansoku : Disqualification" isOpen={isOpen5} toggle={toggleDropdown5}>
                <p>Hansoku : disqualification pour violation des règles.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Le système de points, avantages & pénalités</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <Dropdown title="Ippon (point complet)" isOpen={isOpen6} toggle={toggleDropdown6}>
                <p>Ippon est le score le plus élevé qu'un karatéka peut obtenir dans un combat de karaté. Il est attribué pour une technique parfaite avec un impact décisif.</p>
              </Dropdown>
              <Dropdown title="Waza-ari : Demi-point" isOpen={isOpen7} toggle={toggleDropdown7}>
                <p>Waza-ari est un demi-point attribué lorsque la technique est efficace mais pas suffisamment décisive pour être un Ippon.</p>
              </Dropdown>
              <Dropdown title="Atenai : Avertissement" isOpen={isOpen8} toggle={toggleDropdown8}>
                <p>Atenai est un avertissement donné à un karatéka pour une action illégale.</p>
              </Dropdown>
              <Dropdown title="Hansoku (pénalité majeure)" isOpen={isOpen9} toggle={toggleDropdown9}>
                <p>Hansoku est une pénalité majeure qui entraîne la disqualification du compétiteur.</p>
              </Dropdown>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916560/fake/image_1566626_20211126_ob_8811a7_2017-karate-331_yfpjbo.jpg" alt="Système de notation" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Les actions interdites & sanctions</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916580/fake/download_ulm4d0.jpg" alt="Actions interdites" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Infractions légères : Keikoku" isOpen={isOpen10} toggle={toggleDropdown10}>
                <p>🔸Techniques interdites (par exemple, coup direct au visage sans contrôle).</p>
                <p>🔸Comportement non sportif.</p>
              </Dropdown>
              <Dropdown title="Infractions graves : Hansoku-Make" isOpen={isOpen11} toggle={toggleDropdown11}>
                <p>🔸Comportement dangereux ou blessant intentionnellement l'adversaire.</p>
                <p>🔸Violation majeure des règles de compétition.</p>
              </Dropdown>
              <Dropdown title="Succession des pénalités" isOpen={isOpen12} toggle={toggleDropdown12}>
                <p>🔸L'accumulation de pénalités peut entraîner une disqualification.</p>
                <p>🔸Un athlète peut être disqualifié du tournoi après une disqualification pour infraction grave.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 La durée des combats</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <Dropdown title="Les temps de combat en fonction des catégories" isOpen={isOpen13} toggle={toggleDropdown13}>
                <p>🔸Poussins, Mini-Poussins : 1m</p>
                <p>🔸Benjamins : 1m30</p>
                <p>🔸Minimes : 2m</p>
                <p>🔸Cadets, Juniors, Séniors : 3m</p>
              </Dropdown>
              <Dropdown title="Encho-Sen (prolongation)" isOpen={isOpen14} toggle={toggleDropdown14}>
                <p>Encho-Sen est une prolongation utilisée pour départager les compétiteurs lorsque le temps réglementaire se termine sans qu'un vainqueur soit déterminé. La prolongation continue jusqu'à ce qu'une action décisive se produise.</p>
              </Dropdown>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916737/fake/2020-07-15_News_peb3mv.jpg" alt="Durée des combats" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 Table d'arbitrage & Commissaires Sportifs</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4 order-2 md:order-1">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916898/fake/drapeaux-arbitrage-karate-fond-athletes-uniformes-blancs_681788-22_k8dgbf.avif" alt="Équipement de l'arbitre" className="rounded-lg shadow-md" />
            </div>
            <div className="md:w-1/2 p-4 order-1 md:order-2">
              <Dropdown title="Enregistrement des Scores, du temps, annonce des résultats" isOpen={isOpen15} toggle={toggleDropdown15}>
                <p>🔸Les commissaires sportifs enregistrent les points marqués par les karatékas (Ippon, Waza-ari) et les pénalités (Keikoku, Hansoku) en temps réel.</p>
                <p>🔸Ils supervisent le chronomètre, assurant que la durée des combats et des prolongations est respectée.</p>
                <p>🔸Ils assistent l'arbitre en vérifiant et confirmant les décisions prises sur le tatami, en utilisant parfois des outils vidéo pour les vérifications (révision vidéo).</p>
                <p>🔸Après chaque combat, ils annoncent officiellement le vainqueur en fonction des points et pénalités enregistrés.</p>
              </Dropdown>
              <Dropdown title="Importance de la Table de Marquage" isOpen={isOpen16} toggle={toggleDropdown16}>
                <p>La table de marquage assure une gestion équitable et transparente des combats. Les commissaires sportifs y travaillent en étroite collaboration avec les arbitres pour garantir que les règles du karaté sont appliquées correctement et que les résultats sont précis. Leur rôle est essentiel pour le bon déroulement des compétitions et pour maintenir l'intégrité et l'esprit sportif du karaté.</p>
              </Dropdown>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-4">🟨 La formation des arbitres</h3>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 p-4">
              <p>Des formations d'arbitrage sont organisées par la Fédération Française de Karaté pour former des arbitres et des commissaires sportifs qualifiés.</p>
              <p>Niveaux d'arbitrage (départemental, régional, national, international)</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img src="https://res.cloudinary.com/dy5kblr32/image/upload/v1724916934/fake/IMG_3506-500x250_apnglf.jpg" alt="Formation des arbitres" className="rounded-lg shadow-md" />
            </div>
          </div>
        </div>

      </div>
      <div className="mt-8">
        <a href="https://www.ffkarate.fr/wp-content/uploads/2023/12/Reglement-competitions-2023-2024_VF_13122023_03.pdf" rel="noreferrer" target="_blank" className="text-blue-600 underline">
          Télécharger le document PDF d'arbitrage
        </a>
      </div>
    </div>
  );
}

export default ArbitrageKarate;
