import React from "react";
import { useLocation } from "react-router-dom";
import backgroundImage from "./fond02.webp";
import Button from "./../../components/Button/Button";

function ErrorPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const error = params.get("error") || "Une erreur inconnue est survenue";

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-white p-6 shadow-md text-center rounded-lg flex flex-col ">
        <h1 className="text-xl font-bold text-red-600">Erreur !</h1>
        <p className="text-gray-800 mt-4">{error}</p>
        <Button
          text="Revenir en arrière"
          onClick={() => window.history.back()}
          className="mt-6"
        />
      </div>
    </div>
  );
}

export default ErrorPage;
