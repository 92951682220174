// Catégorie d'âge de Karaté
const getCategory = (dateOfBirth) => {
  if (!dateOfBirth) return "Inconnu";

  const birthYear = new Date(dateOfBirth).getFullYear();
  const currentYear = new Date().getFullYear();

  // Définition des catégories par année de naissance
  if (birthYear >= currentYear - 6) return "Mini-Poussin";
  if (birthYear >= currentYear - 8) return "Poussin";
  if (birthYear >= currentYear - 10) return "Pupille";
  if (birthYear >= currentYear - 12) return "Benjamin";
  if (birthYear >= currentYear - 14) return "Minime";
  if (birthYear >= currentYear - 17) return "Cadet";
  if (birthYear >= currentYear - 20) return "Junior";
  return "Sénior"; // 2003 et avant
};

export default getCategory;