import React, { useEffect, useState } from "react";
import formatDate from "./../../../utils/formatDate";
import {
  getAdherentById,
  updatePoids,
} from "./../../../services/adherentServices";
import Modal from "./../../../hooks/Modal";
import { toast } from "react-toastify";
import Button from "./../../../components/Button/Button";
import { MdPhone, MdMessage, MdEmail } from "react-icons/md";
import { updateAdherentPhoto } from "./../../../services/adherentServices";
import { FaUpload, FaEdit } from "react-icons/fa";
import EditAdherentUserModal from "./EditAdherentUserModal"; // Importer la nouvelle modale

function AdherentDetails({ adherentId }) {
  const [details, setDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newPoids, setNewPoids] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); // État pour la nouvelle modale

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await getAdherentById(adherentId);
        setDetails(data);
        if (data) {
          setNewPoids(data.poids);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de l'adhérent:",
          error
        );
      }
    };

    fetchDetails();
  }, [adherentId]);

  const submitPoidsChange = async () => {
    if (!newPoids) {
      alert("Veuillez entrer un poids valide.");
      return;
    }
    try {
      await updatePoids(adherentId, newPoids);
      setIsEditing(false);
      setDetails({ ...details, poids: newPoids });
      toast.success("Poids mis à jour avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du poids:", error);
      alert("Erreur lors de la mise à jour du poids.");
    }
  };

  const handleCall = () => {
    window.open(`tel:${details.tel}`, "_blank");
  };

  const handleSms = () => {
    window.open(`sms:${details.tel}`, "_blank");
  };

  const handleEmail = () => {
    window.open(`mailto:${details.email}`, "_blank");
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmitPhoto = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Veuillez sélectionner une image.");
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append("image", selectedFile);
      const token = localStorage.getItem("token");
      const updatedDetails = await updateAdherentPhoto(
        adherentId,
        formData,
        token
      );
      if (updatedDetails && updatedDetails.photo_url) {
        setDetails({ ...details, photo_url: updatedDetails.photo_url });
        toast.success("Photo mise à jour avec succès.");
      } else {
        throw new Error("Image not updated properly.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la photo :", error);
      toast.error("Erreur lors de la mise à jour de la photo.");
    } finally {
      setIsUploading(false);
      setShowUploadModal(false);
    }
  };

  const calculateAge = (date_naissance) => {
    const birthDate = new Date(date_naissance);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const openUploadModal = () => setShowUploadModal(true);
  const openEditModal = () => setShowEditModal(true); // Fonction pour ouvrir la nouvelle modale

  if (!details) {
    return (
      <p className="text-center text-gray-500">Chargement des détails...</p>
    );
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-6 pb-2 shadow-lg max-w-[800px] mx-auto font-montserrat">
      <div className="flex gap-4">
        <div className="flex items-center flex-col">
          <img
            src={
              details?.photo_url ||
              (details.genre === "Masculin"
                ? "/img/judoka_avatar.png"
                : "/img/judokate_avatar.png")
            }
            alt={`${details.nom} ${details.prenom}`}
            className="w-36 h-36 object-cover rounded-full"
          />
          <button
            onClick={openUploadModal}
            className="text-blue-500 hover:text-primary dark:text-gray-200 dark:hover:text-primary text-sm mb-2"
          >
            Changer de photo
          </button>
        </div>
        <div className="flex flex-col justify-center">
          <h3 className="text-3xl font-bold dark:text-white">{details.nom}</h3>
          <p className="text-xl text-gray-600 dark:text-white">
            {details.prenom}
          </p>
          <div className="flex space-x-2 mt-4">
            <Button
              text=""
              onClick={handleCall}
              icon={MdPhone}
              hoverLabel="Appeler"
            />
            <Button
              text=""
              onClick={handleSms}
              icon={MdMessage}
              hoverLabel="Envoyer un SMS"
            />
            <Button
              text=""
              onClick={handleEmail}
              icon={MdEmail}
              hoverLabel="Envoyer un email"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-300 dark:bg-gray-600 p-4 rounded relative">
        <h3 className="text-2xl font-light uppercase mb-4">
          COORDONNÉES
          <Button
            text=""
            onClick={openEditModal}
            icon={FaEdit}
            hoverLabel="Modifier"
            className="absolute top-4 right-4"
          />
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-800 text-sm">
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Email :</strong>{" "}
            {details.email}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Téléphone :</strong>{" "}
            {details.tel}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Adresse :</strong>{" "}
            {details.adresse || "N/A"}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">CP :</strong>{" "}
            {details.cp || "N/A"}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Commune :</strong>{" "}
            {details.commune || "N/A"}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Genre :</strong>{" "}
            {details.genre}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Date de Naissance :</strong>{" "}
            {formatDate(details.date_naissance)}
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Age :</strong>{" "}
            {calculateAge(details.date_naissance)} ans
          </p>
          <p className="dark:text-gray-200">
            <strong className="dark:text-gray-200">Poids :</strong>{" "}
            {details.poids} Kg
            <button
              onClick={() => setIsEditing(true)}
              className="ml-2 text-blue-500 hover:text-blue-700 dark:bg-slate-300 dark:text-gray-800"
            >
              Modifier
            </button>
          </p>
          {isEditing && (
            <div>
              <input
                type="number"
                className="border px-2 py-1 rounded"
                value={newPoids}
                onChange={(e) => setNewPoids(e.target.value)}
                min="1"
                step="0.1"
              />
              <button
                onClick={submitPoidsChange}
                className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
              >
                Sauvegarder
              </button>
            </div>
          )}
        </div>
        <p className="dark:text-gray-200 mt-4 text-sm">
          <strong className="dark:text-gray-200">Notes Peronnelles :</strong>{" "}
          {details.notes_adherent || "N/A"}
        </p>
        {/* ckeckbox nationalité française */}
        <p className="dark:text-gray-200 mt-4 text-sm">
          <strong className="dark:text-gray-200">Nationalité Française :</strong>{" "}
          {details.nationalite_francaise ? "Non" : "Oui"}
        </p>
      </div>

      {showUploadModal && (
        <Modal
          title="Changer la photo de profil"
          onClose={() => setShowUploadModal(false)}
        >
          <form onSubmit={handleSubmitPhoto}>
            <input
              className="my-4"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
            />
            <div className="flex justify-center gap-4">
              <Button text="Télécharger" type="submit" icon={FaUpload} />
              <Button
                text="Annuler"
                onClick={() => setShowUploadModal(false)}
              />
            </div>
          </form>
        </Modal>
      )}

      {showEditModal && (
        <EditAdherentUserModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          refreshList={() => {
            const fetchDetails = async () => {
              try {
                const data = await getAdherentById(adherentId);
                setDetails(data);
              } catch (error) {
                console.error("Erreur lors de la récupération des détails de l'adhérent:", error);
              }
            };
            fetchDetails();
          }}
          adherent={details}
        />
      )}
    </div>
  );
}

export default AdherentDetails;
