import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "./../../services/passwordServices"; // Importez la fonction du service
import backgroundImage from "./fond02.webp";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await forgotPassword(email);
    if (result.success) {
      setMessage(
        "Si un compte avec cet email existe, un lien de réinitialisation a été envoyé."
      );
      setTimeout(() => navigate("/login"), 5000);
    } else {
      setErrorMessage(result.message); // Assurez-vous d'afficher le message d'erreur retourné par la fonction
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-lg shadow-lg">
        {message && (
          <div
            className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
            role="alert"
          >
            {message}
          </div>
        )}
        {errorMessage && (
          <div
            className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
            role="alert"
          >
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <h1 className="text-center text-3xl font-bold text-gray-900">
            Réinitialisation du mot de passe
          </h1>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
                         focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Entrez votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-primary text-white px-4 py-2 rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-white hover:text-primary border border-primary flex items-center justify-center"
            >
              Envoyer le lien de réinitialisation
            </button>
          </div>
          <div className="p-4 mt-4 text-sm text-blue-700 bg-blue-100 rounded-lg">
            <p>
              Merci de vérifier les spams de votre messagerie pour être sûr de
              recevoir le lien de réinitialisation.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
