import React, { useState, useEffect } from "react";
import TechniqueCard from "./TechniqueCard";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import { getAllTechniques } from "../../../services/techniquesServices";
import { getUserFavorites, addFavorite, removeFavorite } from "../../../services/usersTechniquesServices";

const TechniquesView = () => {
  useScrollToTop();
  const [techniques, setTechniques] = useState([]);
  const [families, setFamilies] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFamille, setSelectedFamille] = useState("Toutes les familles");
  const [showFavorites, setShowFavorites] = useState(false);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  // Récupérer userId depuis localStorage
  const getUserIdFromLocalStorage = () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      return userInfo?.userId;
    } catch (error) {
      console.error("Erreur lors de la récupération de userId depuis localStorage:", error);
      return null;
    }
  };

  const userId = getUserIdFromLocalStorage();

  useEffect(() => {
    const fetchTechniques = async () => {
      const fetchedTechniques = await getAllTechniques();
      setTechniques(fetchedTechniques);
      
      // Générer dynamiquement la liste des familles
      const uniqueFamilies = [...new Set(fetchedTechniques.map(t => t.famille).filter(fam => fam))];
      setFamilies(uniqueFamilies);
    };

    const fetchFavorites = async () => {
      if (userId) {
        const favoritesData = await getUserFavorites(userId);
        setFavorites(favoritesData.map(fav => fav.technique_id));
      }
    };

    fetchTechniques();
    fetchFavorites();
  }, [userId]);

  const handleFamilleChange = (e) => setSelectedFamille(e.target.value);
  const handleSearchChange = (e) => setSearchTerm(e.target.value.toLowerCase());
  const handleShowFavoritesChange = () => setShowFavorites(!showFavorites);

  const handleAddFavorite = async (techniqueId) => {
    await addFavorite(userId, techniqueId);
    setFavorites([...favorites, techniqueId]);
  };

  const handleRemoveFavorite = async (techniqueId) => {
    await removeFavorite(userId, techniqueId);
    setFavorites(favorites.filter(id => id !== techniqueId));
  };

  const filteredTechniques = techniques.filter(
    (technique) =>
      (selectedFamille === "Toutes les familles" || technique.famille === selectedFamille) &&
      (technique.nom && technique.nom.toLowerCase().includes(searchTerm)) &&
      (!showFavorites || favorites.includes(technique.id))
  );

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Techniques
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="overflow-x-auto max-w-[1280px] mx-auto w-full">
        <input
          type="text"
          className="input input-bordered w-full mb-4 mt-2 max-w-{1280px] dark:bg-slate-200 dark:text-black"
          placeholder="Recherche par nom..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <select
              className="select select-bordered w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-slate-200 dark:text-black"
              value={selectedFamille}
              onChange={handleFamilleChange}
            >
              <option value="Toutes les familles">Toutes les familles</option>
              {families.map((famille) => (
                <option key={famille} value={famille}>
                  {famille}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center">
            <label className="mr-2">Afficher les favoris uniquement</label>
            <input
              type="checkbox"
              className="toggle toggle-info"
              checked={showFavorites}
              onChange={handleShowFavoritesChange}
            />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-[1280px] p-4">
        {filteredTechniques.map((technique) => (
          <TechniqueCard
            key={technique.id}
            technique={technique}
            userId={userId}
            onAddFavorite={handleAddFavorite}
            onRemoveFavorite={handleRemoveFavorite}
          />
        ))}
      </div>
    </div>
  );
};

export default TechniquesView;
